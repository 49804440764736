<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-card class="pa-6 mt-n8" width="100%" flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" sm="5">
            <v-btn icon class="mt-n3" @click="cancel()">
              <v-icon> mdi-less-than </v-icon>
            </v-btn>
            <span class="text-h4"> Untitled - {{ today }} </span>
          </v-col>
          <v-col cols="12" md="6" sm="7">
            <v-row class="d-flex justify-end">
              <v-col cols="12" md="4" sm="4" class="text-right mt-n1">
                <v-select
                  v-model="status"
                  outlined
                  :items="statusItems"
                  item-value="name"
                  background-color="white"
                  style="border-radius: 0px"
                >
                  <template v-slot:selection="{ item }">
                    <span
                      v-if="item.name == 'เปิดใช้งาน'"
                      style="color: #11c872"
                    >
                      <v-icon style="color: #11c872"> mdi-circle </v-icon>
                      <v-icon style="color: #11c872"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                      <v-icon style="color: red"> mdi-circle </v-icon>
                      <v-icon style="color: red"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                      <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                      <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span
                      v-if="item.name == 'เปิดใช้งาน'"
                      style="color: #11c872"
                    >
                      <v-icon style="color: #11c872"> mdi-circle </v-icon>
                      <v-icon style="color: #11c872"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                      <v-icon style="color: red"> mdi-circle </v-icon>
                      <v-icon style="color: red"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                    <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                      <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                      <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-divider class="mt-2" vertical style="height: 56px"></v-divider>
              <v-col cols="12" md="3" sm="3" class="text-right">
                <v-btn
                  @click="saveDraft()"
                  text
                  color="#2ab3a3"
                  style="
                    border: solid 1px #2ab3a3;
                    border-radius: 0px;
                    padding: 12px 16px;
                    gap: 8px;
                    width: 114px;
                    height: 48px;
                  "
                  >บันทึกฉบับร่าง</v-btn
                >
              </v-col>
              <v-col cols="12" md="5" sm="5" class="text-right">
                <v-btn
                  @click="submit()"
                  color="#2AB3A3"
                  dark
                  elevation="0"
                  style="
                    border-radius: 0px;
                    padding: 12px 16px;
                    gap: 8px;
                    height: 48px;
                  "
                >
                  ยืนยันการเพิ่มสมัครเรียน
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card class="pa-6" color="#F5F5F5" max-width="100%" flat>
      <v-col>
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          เกี่ยวกับประกาศสมัครเรียน
        </span>
      </v-col>
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-card class="pa-6" width="100%">
          <v-row>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">ชื่อหัวข้อ (TH)</span>
              <span style="color: red">*</span>
              <v-text-field
                hide-details
                v-model="form.name_th"
                placeholder="ชื่อหัวข้อ (TH)"
                dense
                outlined
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">ชื่อหัวข้อ (EN)</span>
              <span style="color: red">*</span>
              <v-text-field
                hide-details
                v-model="form.name_en"
                placeholder="ชื่อหัวข้อ (EN)"
                dense
                outlined
                :rules="rules.required"
              ></v-text-field
            ></v-col>

            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">คำอธิบายแบบย่อ (TH)</span>
              <span style="color: red">*</span>
              <v-textarea
                v-model="form.short_desc_th"
                placeholder="คำอธิบายแบบย่อ (TH)"
                dense
                outlined
                :rules="rules.required"
              ></v-textarea
            ></v-col>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">คำอธิบายแบบย่อ (EN)</span>
              <span style="color: red">*</span>
              <v-textarea
                v-model="form.short_desc_en"
                placeholder="คำอธิบายแบบย่อ (EN)"
                dense
                outlined
                :rules="rules.required"
              ></v-textarea
            ></v-col>
          </v-row>
        </v-card>

        <v-col class="mt-7">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
            >รายละเอียด</span
          >
        </v-col>
        <v-card class="pa-6" width="100%">
          <v-row>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">คุณสมบัติผู้สมัคร (TH)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.qualification_th"
                :content="content"
                :options="editorOption"
                @change="onEditorChange($event)"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">คุณสมบัติผู้สมัคร (EN)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.qualification_en"
                :content="content1"
                :options="editorOption"
                @change="onEditorChange1($event)"
              />
            </v-col>

            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">วิธีการคัดเลือกผู้รับสมัคร (TH)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.seleaction_method_th"
                :content="content2"
                :options="editorOption"
                @change="onEditorChange2($event)"
            /></v-col>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">วิธีการคัดเลือกผู้รับสมัคร (EN)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.seleaction_method_en"
                :content="content3"
                :options="editorOption"
                @change="onEditorChange3($event)"
            /></v-col>

            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">ประมาณการค่าใช้จ่ายตลอดหลักสูตร (TH)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.supposed_budget_th"
                :content="content4"
                :options="editorOption"
                @change="onEditorChange4($event)"
            /></v-col>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">ประมาณการค่าใช้จ่ายตลอดหลักสูตร (EN)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.supposed_budget_en"
                :content="content5"
                :options="editorOption"
                @change="onEditorChange5($event)"
            /></v-col>

            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">กำหนดการรับสมัคร (TH)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.register_schedule_th"
                :content="content6"
                :options="editorOption"
                @change="onEditorChange6($event)"
            /></v-col>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">กำหนดการรับสมัคร (EN)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.register_schedule_en"
                :content="content7"
                :options="editorOption"
                @change="onEditorChange7($event)"
            /></v-col>

            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">วิธีการสมัคร (TH)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.register_method_th"
                :content="content8"
                :options="editorOption"
                @change="onEditorChange8($event)"
            /></v-col>
            <v-col cols="12" md="6" sm="6"
              ><span class="pt-2">วิธีการสมัคร (EN)</span>
              <span style="color: red">*</span>
              <quill-editor
                class="quill233"
                v-model="form.register_method_en"
                :content="content9"
                :options="editorOption"
                @change="onEditorChange9($event)"
            /></v-col>
          </v-row>
        </v-card>

        <v-col class="mt-10">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            อัพโหลดเอกสาร
          </span>
        </v-col>
        <v-card class="pa-6" width="100%">
          <v-row>
            <v-col cols="12" md="3" sm="3"
              ><span class="pt-2"><b>เอกสาร</b></span>
              <span style="color: red">*</span></v-col
            >
            <v-col cols="12" md="9" sm="9">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  v-for="(item, indexItemAnnouces) in this.imageEnrollAnnounces"
                  :key="indexItemAnnouces"
                >
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <p
                        style="color: #4caf50; cursor: pointer"
                        @click="onClickFile(item.path)"
                      >
                        {{ item.name }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="9" sm="9" class="mt-n3">
                      <v-btn
                        color="red"
                        icon
                        @click="deleteFilesAnnounces(indexItemAnnouces)"
                      >
                        <v-icon>mdi-alpha-x-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <input
                    type="file"
                    accept="*,"
                    id="file"
                    ref="form.attachFile1"
                    v-on:change="submitFile('form.attachFile1')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <!-- <v-card class="pa-6 mt-4" width="100%">
          <v-row>
            <v-col cols="12" md="3" sm="3"
              ><span class="pt-2"><b>ใบสมัคร</b></span>
              <span style="color: red">*</span></v-col
            >
            <v-col cols="12" md="9" sm="9">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  v-for="(item, indexItemRegisters) in this
                    .imageEnrollRegisters"
                  :key="indexItemRegisters"
                >
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <p
                        style="color: #4caf50; cursor: pointer"
                        @click="onClickFile(item.path)"
                      >
                        {{ item.name }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="9" sm="9" class="mt-n3">
                      <v-btn
                        color="red"
                        icon
                        @click="deleteFilesRegisters(indexItemRegisters)"
                      >
                        <v-icon>mdi-alpha-x-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <input
                    type="file"
                    accept="*,"
                    id="file"
                    ref="form.attachFile2"
                    v-on:change="submitFile('form.attachFile2')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card> -->

        <!-- <v-row align="center" justify="end">
            <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
            <v-btn @click="submit()" dark color="#2AB3A3">บันทึก</v-btn>
          </v-row> -->
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode, Encode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      form: {
        name_th: null,
        name_en: null,
        short_desc_th: null,
        short_desc_en: null,
        semesterCourse: null,
        qualification_th: null,
        qualification_en: null,
        seleaction_method_th: null,
        seleaction_method_en: null,
        supposed_budget_th: null,
        supposed_budget_en: null,
        register_schedule_th: null,
        register_schedule_en: null,
        attachFile1: "",
        attachFile2: "",
        register_method_th: null,
        register_method_en: null,
        levelId: 13,
      },
      imageEnrollAnnounces: [],
      imageEnrollRegisters: [],

      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",
      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      getToday: new Date().toLocaleString(),
      today: "",
      status: "เปิดใช้งาน",
      statusItems: [
        { id: 1, name: "เปิดใช้งาน" },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
        {
          id: 3,
          name: "ฉบับร่าง",
        },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      loading: false,
    };
  },
  watch: {},
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.setDate();
  },
  methods: {
    deleteFilesAnnounces(val) {
      this.imageEnrollAnnounces.splice(val, 1);
    },
    deleteFilesRegisters(val) {
      this.imageEnrollRegisters.splice(val, 1);
    },
    setDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      this.today = date;
    },

    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content5 = html;
    },
    onEditorChange6({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content6 = html;
    },
    onEditorChange7({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content7 = html;
    },
    onEditorChange8({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content8 = html;
    },
    onEditorChange9({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content9 = html;
    },

    cancel() {
      this.$router.push("ManageRegisterPhD");
    },

    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          ...this.form,
          imageEnrollAnnounces: this.imageEnrollAnnounces,
          imageEnrollRegisters: this.imageEnrollRegisters,
          status: this.status,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/enrolls`,
          data,
          // this.form,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างใหม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageRegisterPhD");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async saveDraft() {
      this.status = "ฉบับร่าง";

      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        ...this.form,
        imageEnrollAnnounces: this.imageEnrollAnnounces,
        imageEnrollRegisters: this.imageEnrollRegisters,
        status: this.status,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/enrolls`,
        data,
        // this.form,
        auth
      );
      console.log("createBanner", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `สร้างใหม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("ManageRegisterPhD");
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "form.attachFile1") {
            // this.form.attachFile1 = response.data.data.path;
            this.imageEnrollAnnounces.push({
              name: this.$refs[questionNo].files[0].name,
              path: response.data.data.path,
            });
          }
          if (questionNo == "form.attachFile2") {
            // this.form.attachFile2 = response.data.data.path;
            this.imageEnrollRegisters.push({
              name: this.$refs[questionNo].files[0].name,
              path: response.data.data.path,
            });
          }
          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },
  },
};
</script>
